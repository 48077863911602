export const DealsSVG = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1287_4274)">
      <path
        d="M7.6866 1.25212C7.8696 1.1049 8.1304 1.1049 8.31341 1.25212L9.87704 2.51C9.96205 2.57839 10.0671 2.6171 10.1762 2.62021L12.1719 2.67726C12.4044 2.6839 12.6016 2.84994 12.6477 3.07788L13.0519 5.0751C13.0731 5.18009 13.1275 5.27548 13.2071 5.34722L14.7101 6.7028C14.8804 6.85648 14.9244 7.10643 14.8166 7.30899L13.859 9.10826C13.809 9.20222 13.7902 9.30969 13.8055 9.41502L14.0992 11.4374C14.1324 11.6657 14.005 11.8871 13.791 11.9732L11.9285 12.7224C11.8282 12.7628 11.7436 12.8346 11.6876 12.9271L10.6388 14.6573C10.5174 14.8576 10.2732 14.9467 10.0513 14.8718L8.15991 14.2334C8.05618 14.1984 7.94382 14.1984 7.84009 14.2334L5.94866 14.8718C5.72675 14.9467 5.48258 14.8576 5.36117 14.6573L4.31244 12.9271C4.25638 12.8346 4.17182 12.7628 4.07147 12.7224L2.20899 11.9732C1.99498 11.8871 1.86764 11.6657 1.90079 11.4374L2.19446 9.41502C2.20975 9.30969 2.19104 9.20222 2.14103 9.10826L1.18344 7.30899C1.07564 7.10643 1.11955 6.85648 1.28995 6.7028L2.79292 5.34722C2.87246 5.27548 2.92686 5.18009 2.94811 5.0751L3.3523 3.07788C3.39842 2.84994 3.59561 2.6839 3.82808 2.67726L5.82384 2.62021C5.9329 2.6171 6.03795 2.57839 6.12296 2.51L7.6866 1.25212Z"
        stroke="#444444"
      />
      <path
        d="M10.5 5.5L5.5 10.5"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 10C10.5 10.2761 10.2761 10.5 10 10.5C9.72386 10.5 9.5 10.2761 9.5 10C9.5 9.72386 9.72386 9.5 10 9.5C10.2761 9.5 10.5 9.72386 10.5 10Z"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 6C6.5 6.27614 6.27614 6.5 6 6.5C5.72386 6.5 5.5 6.27614 5.5 6C5.5 5.72386 5.72386 5.5 6 5.5C6.27614 5.5 6.5 5.72386 6.5 6Z"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1287_4274">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const GuidedToursIconSVG = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.69643 10.4999V9.8035C6.69643 9.43409 6.54968 9.07981 6.28847 8.8186C6.02726 8.55739 5.67298 8.41064 5.30357 8.41064H2.51786C2.14845 8.41064 1.79417 8.55739 1.53296 8.8186C1.27175 9.07981 1.125 9.43409 1.125 9.8035V10.4999"
      stroke="#444444"
      strokeWidth="0.928571"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.75028 7.28571C4.51953 7.28571 5.14314 6.66211 5.14314 5.89286C5.14314 5.1236 4.51953 4.5 3.75028 4.5C2.98103 4.5 2.35742 5.1236 2.35742 5.89286C2.35742 6.66211 2.98103 7.28571 3.75028 7.28571Z"
      stroke="#444444"
      strokeWidth="0.928571"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.75 7.125L6.75 3.99107M6.75 3.99107V0.996429C6.75 0.930168 6.81741 0.88523 6.87857 0.910715L10.6997 2.50283C10.7789 2.53585 10.7747 2.64949 10.6933 2.67664L6.75 3.99107Z"
      stroke="#444444"
      strokeWidth="0.928571"
      strokeLinecap="round"
    />
  </svg>
);

export const TicketSVG = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_970_4242)">
      <path
        d="M8.4375 7.5L10.62 3.6825L9.4725 3C9.25212 3.12316 9.00954 3.20148 8.75875 3.23042C8.50796 3.25937 8.25392 3.23837 8.01128 3.16865C7.76864 3.09893 7.54221 2.98185 7.34504 2.82418C7.14787 2.66651 6.98387 2.47137 6.8625 2.25C6.72059 1.98881 6.63861 1.69931 6.6225 1.4025V1.365L5.4825 0.75L3.3075 4.575L1.125 8.355L2.265 9C2.53687 8.85899 2.83647 8.77961 3.1425 8.7675C3.39369 8.75848 3.6442 8.79906 3.87969 8.88692C4.11519 8.97479 4.33106 9.10821 4.51495 9.27957C4.69884 9.45092 4.84715 9.65684 4.9514 9.88556C5.05565 10.1143 5.11379 10.3613 5.1225 10.6125L6.27 11.2725L8.4375 7.5Z"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 5.625L4.5 3"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_970_4242">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const MustSeeAttractionsSVG = ({
  className,
}: {
  className?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="46"
    viewBox="0 0 44 46"
    fill="none"
    className={className}
  >
    <path
      opacity="0.7216"
      d="M43.8802 12.1672V36.4124C43.8802 37.2852 43.1801 37.996 42.3105 38.0786C41.6211 38.1429 40.8524 38.2448 40.0761 38.396C39.5722 38.4935 39.065 38.6125 38.575 38.753C37.2884 39.124 36.1155 39.6568 35.411 40.4042C33.7009 42.2194 29.7467 44.7626 25.6177 45.6664C24.4758 45.9152 23.32 46.0395 22.1942 45.9881C17.0016 45.7501 12.8716 43.7901 8.85948 40.4042C8.6429 40.2294 8.42525 40.0696 8.20759 39.9238C7.96743 39.7619 7.72511 39.6161 7.47958 39.4864C5.86595 38.6233 4.11078 38.3938 1.72625 38.3402C0.84384 38.3198 0.12655 37.6122 0.12655 36.7298C0.125478 32.7681 0.122262 23.0788 0.120117 12.0857C0.120117 11.2376 0.782726 10.5374 1.62439 10.4238C3.93173 10.1139 7.26729 9.32159 9.09 7.70259C11.9399 5.17117 14.9581 2.63867 21.5809 2.63867C28.2038 2.63867 31.3056 4.83343 34.2391 7.70259C36.4489 9.86411 40.0847 10.397 42.2826 10.5289C43.164 10.5814 43.8802 11.2837 43.8802 12.1672Z"
      fill="url(#paint0_linear_757_11840)"
    />
    <path
      d="M43.8803 27.011V36.413C43.8803 37.2857 43.1801 37.9966 42.3106 38.0791C41.6212 38.1435 40.8524 38.2453 40.0762 38.3965C37.7206 35.8083 33.7803 32.1092 28.265 29.0106C18.7387 23.6583 11.9539 25.5432 10.3746 26.1125C10.1119 26.2069 9.8138 26.1511 9.61223 25.957C9.24554 25.6043 8.91317 24.9438 9.95425 24.0185C9.87277 24.0571 7.77558 25.0489 7.01647 23.788C6.24236 22.5035 7.97179 21.0046 11.6419 20.7441C11.6419 20.7441 8.95498 20.0365 6.14479 20.0879C5.25916 20.1051 4.56439 19.3181 4.72415 18.4475C4.84959 17.7624 5.34387 17.0719 6.71412 16.7995C9.27342 16.2892 15.3334 18.5526 15.3334 18.5526C15.3334 18.5526 14.2848 16.3825 15.3623 14.5865C16.13 13.3085 17.6622 12.5987 19.13 11.8889C19.4613 11.7281 19.7883 11.5684 20.1025 11.4011C20.185 11.3571 20.2708 11.3271 20.3576 11.311C20.7651 11.2295 21.1929 11.4279 21.3816 11.8225C21.7322 12.5548 21.8866 13.6248 20.4981 14.4246C20.1904 14.6026 19.9256 14.7731 19.6993 14.9393C19.4377 15.1312 19.2276 15.3156 19.0635 15.4925C18.2991 16.3181 18.5199 16.9904 19.0646 17.5694C19.9159 18.4711 21.907 19.0415 23.645 19.0726H23.6492C23.8401 19.0768 24.0288 19.0736 24.2111 19.064C24.2111 19.064 24.5252 18.8442 24.8115 18.47C25.0817 18.1183 25.3261 17.6283 25.2565 17.0601C25.1139 15.8882 22.2372 14.8332 22.2554 12.8303C22.2554 12.8303 22.2415 12.3661 22.502 11.9436C22.7218 11.5866 23.2032 11.4987 23.5452 11.741C24.1178 12.1473 25.1857 12.8636 26.3833 13.465C28.1696 14.3625 30.5498 15.5215 31.8836 18.946C31.8836 18.946 38.246 22.3427 43.8803 27.011Z"
      fill="#FFC6AF"
    />
    <path
      d="M19.4108 12.1403C19.3336 12.0835 19.2189 11.9773 19.1299 11.8894C19.4612 11.7286 19.7882 11.5688 20.1024 11.4016C20.1849 11.3576 20.2707 11.3276 20.3575 11.3115C20.5184 11.6986 19.5448 12.2368 19.4108 12.1403Z"
      fill="white"
    />
    <path
      d="M23.8109 12.3241C23.7177 12.4849 23.5665 12.7508 23.455 12.976C23.3874 13.1132 23.2052 13.1443 23.0958 13.0371C22.8878 12.8323 22.6584 12.4828 22.8942 12.0668C22.8942 12.0668 23.1548 11.5714 23.7584 12.0571C23.8399 12.1225 23.8635 12.2351 23.8109 12.3241Z"
      fill="white"
    />
    <path
      d="M6.4694 18.2255C6.51122 18.4968 6.58412 18.9439 6.66561 19.3031C6.71493 19.5218 6.52623 19.7523 6.29249 19.7566C5.84754 19.7641 5.44011 19.6022 5.33718 18.9728C5.33718 18.9728 5.13775 18.0818 6.15204 17.9993C6.31072 17.9864 6.44581 18.0743 6.4694 18.2255Z"
      fill="white"
    />
    <path
      d="M8.30002 22.563C8.42546 22.8075 8.63561 23.2085 8.82538 23.5237C8.94118 23.7156 8.83503 23.9944 8.61416 24.0716C8.19387 24.2196 7.75642 24.1938 7.4605 23.6288C7.4605 23.6288 6.98981 22.8461 7.92689 22.4483C8.07378 22.3861 8.23032 22.4269 8.30002 22.563Z"
      fill="white"
    />
    <path
      d="M10.3657 24.783C10.4558 24.9556 10.608 25.2398 10.7453 25.4628C10.8289 25.5989 10.7549 25.7941 10.5984 25.8477C10.3003 25.9495 9.98939 25.9292 9.77495 25.5293C9.77495 25.5293 9.43614 24.9749 10.0998 24.7005C10.2038 24.6565 10.3153 24.6865 10.3657 24.783Z"
      fill="white"
    />
    <path
      d="M33.2755 19.7253C33.2755 19.7253 36.3538 21.385 31.396 28.3864L34.4807 30.5297C34.4807 30.5297 38.7501 25.7134 37.3123 22.1141C37.1697 21.7582 36.9145 21.4601 36.5918 21.2542C35.7651 20.7246 34.0025 19.5956 33.7612 19.4476C33.4449 19.2525 33.2755 19.7253 33.2755 19.7253Z"
      fill="black"
    />
    <path
      d="M28.2565 28.433C28.754 27.3587 30.9101 27.4488 32.9065 28.5499C34.9029 29.651 35.8894 31.1414 35.5924 32.2875C35.5784 32.339 35.1935 33.1099 35.1485 33.1313C34.2296 33.5677 32.4562 33.2 30.8351 32.3047C29.4177 31.522 27.8984 30.1861 27.9048 29.2093C27.9048 29.1525 28.2329 28.4845 28.2565 28.433Z"
      fill="black"
    />
    <path
      d="M35.1651 33.1186C35.7373 32.0816 34.5828 30.3482 32.5867 29.2468C30.5905 28.1454 28.5085 28.0932 27.9363 29.1302C27.3642 30.1672 28.5186 31.9006 30.5148 33.002C32.511 34.1034 34.593 34.1556 35.1651 33.1186Z"
      fill="white"
    />
    <path
      d="M31.794 31.8018C31.7308 31.8114 31.6654 31.8007 31.6075 31.7696L29.9477 30.8862C29.8169 30.8165 29.7676 30.6546 29.8373 30.5238C29.907 30.3929 30.0689 30.3436 30.1997 30.4133L31.5367 31.1253L31.5731 30.4487C31.5807 30.3007 31.7072 30.1882 31.8551 30.1957C32.0031 30.2032 32.1167 30.3297 32.1082 30.4777L32.0513 31.5187C32.046 31.6227 31.9881 31.716 31.8969 31.7664C31.8648 31.7836 31.8294 31.7954 31.794 31.8018Z"
      fill="black"
    />
    <path
      d="M13.0807 11.666C12.8502 11.9566 12.5018 12.3061 12.0032 12.3844C11.5046 12.4626 11.1712 11.8644 11.5797 11.606C12.0064 11.3368 12.2423 11.2221 12.4664 10.7536C12.6905 10.284 12.8534 9.96231 13.2116 10.1264C13.6447 10.3226 13.6029 11.0077 13.0807 11.666Z"
      fill="white"
      fillOpacity="0.6"
    />
    <path
      d="M36.1438 14.7311C36.1438 14.7311 35.9369 13.6857 34.9676 13.4531C33.9984 13.2204 32.9144 14.7225 33.443 15.0442C33.8633 15.2994 34.112 14.834 34.3436 14.5488C34.5741 14.2647 34.9805 14.2647 35.2389 14.8566C35.4962 15.4484 35.2539 15.9545 35.761 16.1057C36.0698 16.1979 36.2253 15.9234 36.3443 15.7304C36.4633 15.5374 37.0541 14.7343 38.0426 15.2576C38.0426 15.2576 38.3203 15.4087 38.4758 15.7776C38.6302 16.1464 39.2446 15.9727 39.1609 15.3766C39.0548 14.6239 37.558 13.4338 36.1438 14.7311Z"
      fill="white"
      fillOpacity="0.6"
    />
    <path
      d="M5.56381 29.9815C5.30863 30.0126 5.11779 30.2281 5.11457 30.4854C5.10277 31.5319 5.41156 34.1105 8.58415 35.1355C12.2017 36.3042 14.3107 31.9361 11.969 30.613C9.64133 29.2974 8.14992 32.1516 9.33254 34.0622C10.4058 35.7938 12.9415 36.7191 15.2392 35.2105C15.5072 35.0347 15.5769 34.6734 15.3872 34.416C15.2188 34.1887 14.9057 34.1244 14.6624 34.2681C13.9279 34.7034 12.3389 35.3874 10.9687 34.222C9.11703 32.6459 10.4272 31.0387 11.359 31.5329C12.2907 32.0272 11.5927 34.8835 8.43726 33.8917C6.52878 33.2924 6.18461 31.4043 6.13421 30.4597C6.12028 30.1648 5.85759 29.945 5.56381 29.9815Z"
      fill="white"
      fillOpacity="0.6"
    />
    <path
      d="M23.4321 39.7769C23.3989 39.764 23.3656 39.7479 23.3345 39.7286C23.0836 39.571 23.0075 39.2408 23.1651 38.9899C23.6069 38.2844 24.4904 37.8834 25.3116 38.0164C25.6204 38.0657 25.9217 38.1901 26.1865 38.3766C26.3656 38.5031 26.6229 38.4785 26.773 38.3219C27.0432 38.041 27.3542 37.8137 27.6962 37.6454C27.9621 37.5157 28.2827 37.625 28.4135 37.8909C28.5432 38.1568 28.4338 38.4774 28.1679 38.6082C27.9385 38.7208 27.7294 38.8741 27.5471 39.0639C27.0304 39.6021 26.1801 39.6825 25.57 39.2526C25.4382 39.1604 25.2902 39.0982 25.1422 39.0746C24.7402 39.0103 24.2909 39.214 24.0743 39.5592C23.935 39.778 23.6648 39.8637 23.4321 39.7769Z"
      fill="white"
      fillOpacity="0.6"
    />
    <path
      d="M21.2617 15.3858V0.482482C21.2617 0.215509 21.4772 0 21.7442 0C22.0112 0 22.2267 0.215509 22.2267 0.482482V15.3858C22.2267 15.6528 22.0112 15.8683 21.7442 15.8683C21.4772 15.8683 21.2617 15.6517 21.2617 15.3858Z"
      fill="#121110"
    />
    <path
      d="M20.9766 1.48889V6.34373C20.9766 6.56246 21.1942 6.71471 21.399 6.63965L29.3106 3.7662C29.6033 3.66006 29.5808 3.23976 29.2795 3.16364L21.3679 1.18224C21.1696 1.13292 20.9766 1.2841 20.9766 1.48889Z"
      fill="#FF505A"
    />
    <path
      d="M19.6993 14.9384C19.4377 15.1303 19.2275 15.3147 19.0635 15.4916C19.1107 15.4112 19.1546 15.3297 19.1921 15.2439C19.3283 14.9298 19.354 14.5706 19.2661 14.2404C19.2275 14.0967 19.1814 13.9133 19.3004 13.8254C19.3841 13.7632 19.5116 13.7965 19.5824 13.8737C19.6542 13.9498 19.6821 14.057 19.7004 14.16C19.7454 14.4173 19.7443 14.681 19.6993 14.9384Z"
      fill="#121110"
    />
    <path
      d="M25.9895 18.645C25.3451 18.9495 24.7189 19.06 24.1968 19.0814L24.2096 19.0632H24.2107C24.2107 19.0632 24.5249 18.8434 24.8111 18.4692C25.1135 18.4113 25.4351 18.3137 25.7611 18.1604C25.894 18.0971 26.0538 18.155 26.1171 18.2891C26.1803 18.4231 26.1235 18.5828 25.9895 18.645Z"
      fill="#121110"
    />
    <path
      d="M32.2477 20.9229C32.2252 20.9229 32.2026 20.9197 32.1801 20.9143C32.0375 20.8768 31.9517 20.732 31.9882 20.5884C31.9936 20.5658 32.3581 18.9715 30.6941 16.7671C30.6051 16.6492 30.6287 16.4808 30.7466 16.3918C30.8646 16.3028 31.0329 16.3264 31.1219 16.4444C32.9596 18.8772 32.5264 20.6484 32.5071 20.7224C32.476 20.8425 32.3667 20.9229 32.2477 20.9229Z"
      fill="#121110"
    />
    <path
      d="M16.2329 23.5549C16.1729 23.5549 16.1118 23.5346 16.0614 23.4927C15.6261 23.1303 15.3891 22.6865 15.2862 22.4538C15.2648 22.4045 15.2272 22.3659 15.1801 22.3444C13.2437 21.447 10.7326 21.1211 10.7069 21.1179C10.56 21.0996 10.456 20.9656 10.4742 20.8187C10.4925 20.6718 10.6265 20.5678 10.7734 20.5861C10.8795 20.5989 13.3906 20.9249 15.4041 21.8587C15.5693 21.9359 15.7011 22.07 15.7751 22.2383C15.8587 22.4281 16.0507 22.7883 16.4023 23.08C16.516 23.1743 16.5321 23.3437 16.4377 23.4574C16.3863 23.5217 16.3091 23.5549 16.2329 23.5549Z"
      fill="#121110"
    />
    <path
      d="M9.72939 24.5946C9.62646 24.5946 9.52782 24.5345 9.48386 24.4337C9.42489 24.2976 9.48601 24.14 9.62217 24.081C9.71438 24.0403 11.9059 23.0935 14.5253 22.9777C14.6743 22.9724 14.7987 23.086 14.8051 23.234C14.8115 23.3819 14.6968 23.5074 14.5489 23.5138C12.0282 23.6242 9.85912 24.5624 9.83768 24.5721C9.8023 24.5871 9.76477 24.5946 9.72939 24.5946Z"
      fill="#121110"
    />
    <path
      d="M15.7753 20.1639C15.6928 20.1639 15.6124 20.1263 15.5598 20.0556C15.5341 20.0213 14.9251 19.1871 14.4909 17.6968C14.449 17.5542 14.5316 17.4062 14.6731 17.3644C14.8157 17.3226 14.9637 17.4051 15.0055 17.5467C15.4108 18.9384 15.9855 19.7286 15.9908 19.7371C16.0788 19.8561 16.053 20.0245 15.934 20.1113C15.8858 20.1467 15.83 20.1639 15.7753 20.1639Z"
      fill="#121110"
    />
    <defs>
      <linearGradient
        id="paint0_linear_757_11840"
        x1="20.842"
        y1="-0.470507"
        x2="23.0525"
        y2="46.9584"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE3D7" />
        <stop offset="0.4229" stopColor="#FFE1E0" />
        <stop offset="1" stopColor="#EFCFFF" />
      </linearGradient>
    </defs>
  </svg>
);
